import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import BlogPost from 'components/blog/blogPost';
import BlogPostQueryData from 'interfaces/BlogPostQueryData';
import { BlogPostPageContext } from 'interfaces/blogPost';

const BlogPostTemplate = ({ 
  data, 
  location, 
  pageContext,
}: { 
  data: BlogPostQueryData;
  location: Location;
  pageContext: BlogPostPageContext;
}) => {
  const { mdx } = data; 
  const { entryNumber, previousBlogPost, nextBlogPost } = pageContext;

  return(
    <Layout 
      seo={{
        title: mdx.frontmatter.title,
        description: mdx.excerpt,
      }}
      location={location}
    >
      <BlogPost 
        mdx={mdx}
        entryNumber={entryNumber}
        previousBlogPost={previousBlogPost}
        nextBlogPost={nextBlogPost}
      />
    </Layout>
  );
};

export const query = graphql`
  query BLOG_POST_QUERY($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        publishedAt
      }
      slug
      body
      timeToRead
      fileAbsolutePath
      excerpt
    }
  }
`;

export default BlogPostTemplate;