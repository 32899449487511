import styled from '@emotion/styled';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../../style';

export const BlogPost = styled.div`
  ${Shared.indexLayoutStyle}
  
  padding: 16vh ${SPACER.base} ${SPACER.large};
  justify-content: space-between;
`;

export const Heading = styled.h1<{ theme: CustomTheme }>`
  font-size: 56px;
  line-height: 1.125;
  max-width: 936px;
  font-family: ${({ theme }) => theme.fonts.serif};
`;

export const Caption = styled.div<{ theme: CustomTheme }>`
  font-family: ${({ theme }) => theme.fonts.monospace};
  font-size: 16px;
  margin-top: ${SPACER.xsmall};
  line-height: 1.5;
`;

export const Body = styled.div`
  margin-top: ${SPACER.x2large};

  .image-container {
    padding-bottom: ${SPACER.large};

    > span {
      width: 100%;
    }
  }

  .UNSAFE__side-by-side {
    display: grid; 
    grid-template-columns: repeat(auto-fit, minmax(min(256px, 100%), 1fr));
    grid-gap: ${SPACER.small};
  }

  .footnotes {
    font-size: 16px; 
    max-width: 936px;
    line-height: 1.25;
    padding: ${SPACER.base} 0;

    hr {
      border-top: dashed 1px;
      border-bottom: none;
    }

    ol {
      list-style: decimal inside;

      li {
        margin: ${SPACER.base} 0;
      }
    }
  }
`;