import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import BlogPostNav from './blogPostNav';
import { BlogPostData } from 'interfaces/BlogPostQueryData';
import { BlogPostNavData } from 'interfaces/blogPost';
import CustomLink from 'components/customLink';

import * as Styled from './style';
import { formatDate } from '../utils';

const BlogPost = ({ 
  mdx, 
  entryNumber,
  previousBlogPost,
  nextBlogPost,
} : { 
  mdx: BlogPostData;
  entryNumber: number;
  previousBlogPost: BlogPostNavData;
  nextBlogPost: BlogPostNavData;
}) => {
  const { 
    frontmatter: { 
      title, 
      publishedAt, 
    }, 
    body, 
    timeToRead,
  } = mdx; 

  return(
    <Styled.BlogPost>
      <div>
        <Styled.Heading>{title}</Styled.Heading>

        <Styled.Caption>
          Entry {`${entryNumber}`.padStart(2, '0')} ✸{' '}
          {formatDate(publishedAt)} ✸{' '}
          {`${timeToRead}`.padStart(2, '0')} min read{' '}
        </Styled.Caption>

        <Styled.Body>
          <MDXProvider
            components={{
              a: CustomLink,
            }}
          >
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </Styled.Body>
      </div>

      <BlogPostNav 
        previousBlogPost={previousBlogPost}
        nextBlogPost={nextBlogPost}
      />
    </Styled.BlogPost>
  );
};

const createGitHubUrl = (fileAbsolutePath: string) => {
  const relativePath = fileAbsolutePath.split('/src/')[1];
  
  return(
    `https://github.com/Yihwan/yihwan-dot-kim-v2/blob/master/src/${relativePath}`
  );
};

export default BlogPost;
