import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

export const BlogPostNav = styled.div`
  display: grid; 
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${SPACER.base};

  padding: ${SPACER.base} 0;
`;

export const InlineContainer = styled.div<{isNext?: boolean}>`
  display: inline-flex;

  ${({ isNext }) => isNext && `
    justify-self: end;
    text-align: right;
  `}
`;

export const NavLink = styled(Link)<{ theme: CustomTheme; }>`
  display: flex; 
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.monospace};

  text-decoration: none; 

  &:hover {
    text-decoration: underline dashed;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
  }
`;

export const Arrow = styled.span`
  font-size: 32px; 
  font-weight: bold; 
`;
  
export const NavTitle = styled.span`
  margin-top: ${SPACER.xsmall};
  font-size: 16px;
  line-height: 1.125;
`;

