import React from 'react';

import { BlogPostNavData } from 'interfaces/blogPost';

import * as Styled from './style';

const BlogPostNav = ({ 
  previousBlogPost, 
  nextBlogPost,
} : {  
  previousBlogPost: BlogPostNavData; 
  nextBlogPost: BlogPostNavData;
}) => {
  const previousUrl = `/blog/${previousBlogPost ? previousBlogPost.slug : ''}`;
  const previousText = previousBlogPost ? previousBlogPost.title : 'All Posts';
  
  const nextUrl = `/blog/${nextBlogPost ? nextBlogPost.slug : ''}`;
  const nextText = nextBlogPost ? nextBlogPost.title : 'All Posts';

  return(
    <Styled.BlogPostNav>
      <Styled.InlineContainer>
        <Styled.NavLink to={previousUrl}>
          <Styled.Arrow>←</Styled.Arrow>
          <Styled.NavTitle>{previousText}</Styled.NavTitle>
        </Styled.NavLink>
      </Styled.InlineContainer>
      
      <Styled.InlineContainer isNext={true}>
        <Styled.NavLink to={nextUrl}>
          <Styled.Arrow>→</Styled.Arrow>
          <Styled.NavTitle>{nextText}</Styled.NavTitle>
        </Styled.NavLink>
      </Styled.InlineContainer>
    </Styled.BlogPostNav>
  );
};

export default BlogPostNav;